import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import { Grid, Typography, Hidden } from "@material-ui/core"
import Bar from "../components/bar"
import Image from "../components/image"

const AboutUs = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="About Us" />
        <Grid container spacing={3} style={{ minHeight: 500, marginTop: 20 }}>
          <Hidden xsDown>
            <Grid
              item={true}
              sm={4}
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              style={{ padding: 10 }}
            >
              <div style={{ width: "100%" }}>
                <Image
                  alt="Rachelle Punzalan"
                  filename="rachelle-headshot.png"
                />
              </div>
            </Grid>
          </Hidden>
          <Grid
            item={true}
            xs={12}
            sm={8}
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            style={{ paddingLeft: 40, paddingRight: 40 }}
          >
            <Typography variant="h4">
              Rachelle L. Punzalan, B.A., J.D.
            </Typography>
            <Bar />
            <Typography variant="body1" style={{ marginTop: 20 }}>
              Rachelle Punzalan graduated with a B.A. in Political Science form
              the Ateneo de Manila University, as a full academic scholar. After
              University, she finised her Juris Doctor degree at the Atneo
              School of Law and wrote a thesis regarding constitutional change.
              She then received a Certificate of Qualification from the National
              Committee on Accreditation of the Federation of Law Societies of
              Canada.
            </Typography>
            <br />
            <Typography variant="body1">
              Rachelle articled in 2012 and was called to the Manitoba bar in
              June 2013. She joined the law firm of Restall &amp; Restall LLP in
              Winnipeg, Manitoba, where she was an associate lawyer for three
              years. She opened her own law firm in Winnipeg, Punzalan Law, in
              July 2016.
            </Typography>
            <br />
            <Typography variant="body1">
              Outside of her law practice, she plays chess and golf and is an
              active volunteer at the annual Physio FitRun. She is also a
              volunteer for her children's school and hockey fundraising
              activites.
            </Typography>

            <Typography variant="h5" style={{ marginTop: 20 }}>
              Education
            </Typography>
            <Bar />
            <Typography variant="body1">
              Ateneo de Manila University
              <br />
              B. A. Political Science (2002)
            </Typography>
            <Typography variant="body1" style={{ marginTop: 20 }}>
              Ateneo de Manila School of Law
              <br />
              Juris Doctor Degree (2007)
            </Typography>
            <Typography variant="body1" style={{ marginTop: 20 }}>
              Recipient of Certificate of Qualification from the National
              Committee on Accreditation of the Federation of Law Societies of
              Canada (2012)
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default AboutUs
